import ActionTypes from '../../../constants/ActionTypes';
import cityClusterMethods from '../../../data/cityClusterMethods';

function add() {
	return dispatch => {
		dispatch(actionAdd(cityClusterMethods));
	};
}

const actionAdd = data => {
	return {
		type: ActionTypes.UNHAB.CITY_CLUSTERS_METHODS.ADD,
		data,
	};
};

const setActiveKeys = keys => {
	return {
		type: ActionTypes.UNHAB.CITY_CLUSTERS_METHODS.SET_ACTIVE_KEYS,
		keys,
	};
};

export default {
	add,
	setActiveKeys,
};
