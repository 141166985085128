import styleVariables from '../styles/_variables.scss';

export const appKey = 'esaUtepUnHabitat';
export const gaId = window?.runtimeConfig?.googleAnalyticsKey;
export const xCubeApp = false;
// Config fallbacks
export const defaultLinks = {
	visat: 'https://visat-dev.gisat.cz/',
	unhab: 'https://esautepunhabitat-dev.gisat.cz/',
	sdg: 'https://visat-sdg-dev.gisat.cz/',
	xcube: 'https://visat-dev.gisat.cz/',
};

// UNHAB APPLICATION ---------------------------------------------------------------
// Components ---
export const MapCoordinatesInfo = 'MapCoordinatesInfo';

// Common configuration -----
export const defaultViewKey = '6f59d02b-7055-4292-ba4a-939d616f9ea9'; // Explore
export const defaultPeriodKey = 'b8de4ca6-b457-4649-b266-dcb9287e4a23';
export const defaultPeriod = '1975/2030';
export const countryLevel = 'a53a54dd-8a0b-4e28-b7aa-aa566cd2ba47';
export const regionLevel = 'b8a2f8d0-41b6-4afc-ba3f-a0df21632975';
export const featuredCountryKeys = [33, 51, 133, 171];
export const reportingPeriod = 5; // In year
export const statisticsGroupsAttributeSets = [
	'59b06cf6-278f-4d4b-8de7-c929ba94ebef', //Urban area
	'4c9aa8c4-3d5b-4fd2-acb7-38026e074d91', //Urbanization area evolution
	'1c2eadd3-abc4-4bc8-b58b-6115e86b5894', //population
	'731cdec7-27cd-483e-895f-4eb6d3bf312f', //population evolution
	'762f0ade-9663-4287-8a5e-8c0e10c89a49',
];
export const areaNameColumns = ['ADM1_NAME', 'ADM0_NAME', 'name'];
export const areaFidColumns = ['ADM0_CODE_ADM1_CODE', 'ADM0_CODE'];

export const admLayerKey = 'border_outlines';
export const admLayerName = 'Administrative boundaries';

export const regionsAreaTreeKey = 'regions';
export const cityClustersAreaTreeKey = 'cityClusters';

//City clusters
export const defaultActiveCityClusterMetodKeys = ['JRC1_GHSL', 'DLR_WSF'];
export const defaultActiveCityClusterMetodKey = ['JRC1_GHSL'];
export const defaultActiveCityClusterFeatureKey1 = '1_1';
export const defaultActiveCityClusterFeatureKey2 = '2_14';

// uniqe key for only indicators layers
// used for indicator layer identification
export const unhabIndicatorLayersRadioGroupKey = 'unhab-indicator-layers';

// Tags
export const tagBaseKey = 'fbf21504-58a2-4429-b9f4-8d35e6630b84';
export const tagExpertKey = '75a8374c-edb3-4944-ba97-a15d6a6d90ba';
export const defaultCoverageTagKey = 'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5';
export const defaultThemeTagKey = '13b7fd98-d80d-4993-8293-77dac979bfe8';
export const defaultSdgTagKey = '1688cdcd-4613-459a-8cd9-638eb1cdb5d0';
export const coverageTagKey = '1bdaa461-23eb-4437-9f03-ea8764a31e87';
export const themeTagKey = 'c75adc77-c487-45a7-8fc5-82f3c7b27c86';
export const sdgTagKey = 'c9bc599b-a6df-4664-8871-3e2e11b5f7c8';
export const sdgIndicatorsOnlyTagKey = '1688cdcd-4613-459a-8cd9-638eb1cdb5d0';
export const globalTagKey = 'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5';
export const partialTagKey = '41a7ab61-037d-4884-bb6e-4b17ddfde9be';

export const modelVisibleTags = [
	'41a7ab61-037d-4884-bb6e-4b17ddfde9be',
	'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5',
	'a90bfb74-5e8a-47a4-a674-0e5a577c376a',
	'3af8b2ab-d56d-417d-b465-df4d6e4819e8',
];

export const combinedDatasetsKeys = [
	'ghsCombinedBuiltUpPop100m',
	'wsfCombinedBuiltupPopulation2016_2023',
	'wsfCombinedBuiltupPopulation2019',
];

// Explore module -----
export const exploreMap1Key = 'exploreMap1';
export const exploreMap2Key = 'exploreMap2';
export const defaultExploreAreaTreeLevelKey =
	'a53a54dd-8a0b-4e28-b7aa-aa566cd2ba47';
export const auSelectionKey = 'auSelection';

// Benchmark module ----
export const benchmarkViewKey = 'a068c9c2-3dbf-4818-87e6-03418bc467a8';

export const areaComparisonType = 'areas';
export const datasetsComparisonType = 'datasets';
export const defaultBenchmarkComparisonType = areaComparisonType;
export const defaultBenchmarkAreaTreeLevelKey =
	'b8a2f8d0-41b6-4afc-ba3f-a0df21632975';
export const benchmarkMap1Key = 'benchmarkMap1';
export const benchmarkMap2Key = 'benchmarkMap2';
export const benchmarkMapKeys = [benchmarkMap1Key, benchmarkMap2Key];
export const benchmarkArea1SelectionKey = 'benchmarkArea1Selection';
export const benchmarkArea2SelectionKey = 'benchmarkArea2Selection';

export const benchmarkArea1SelectionFeatureKey = '133_51331';
export const benchmarkArea2SelectionFeatureKey = '133_51327';
// Report module -----
export const reportViewKey = '7c6dbfaf-1ee7-43db-a5db-8eda9d8182c4';

// XCUBE APPLICATION ----------------------------------------------------------
export const xCubeViewKey = '439cfb29-adc1-487e-9d4f-ff5e87755b96';
export const limassolAreasLayerKey = 'publicGreenAreas';
export const limassolAreasSelectionKey = 'limassolAreasSelection';
export const limassolAreasFidColumnName = 'OBJECTID';
export const limassolAreasNameColumnName = 'address';

// UTILS ----------------------------------------------------------------------
export const alphabet = [
	'A',
	'B',
	'C',
	'D',
	'E',
	'F',
	'G',
	'H',
	'I',
	'J',
	'K',
	'L',
	'M',
	'N',
	'O',
	'P',
	'Q',
	'R',
	'S',
	'T',
	'U',
	'V',
	'W',
	'X',
	'Y',
	'Z',
];

export const distinctColours = [
	'#42d4f4',
	'#e15dda',
	'#f58231',
	'#ffe119',
	'#aaffc3',
	'#9A6324',
	'#dcbeff',
	'#800000',
	'#fffac8',
	'#ff9ec1',
	'#11bda3',
	'#25b537',
	'#e6194B',
	'#911eb4',
	'#bfef45',
	'#808000',
	'#ffd8b1',
	'#4363d8',
	'#282892',
];

export const fourCountriesColors = [
	{
		key: '171',
		name: 'Myanmar',
		color: '#c65b7c',
	},
	{
		key: '33',
		name: 'Bolivia',
		color: '#5b8dc6',
	},
	{
		key: '51',
		name: 'Chile',
		color: '#5bc670',
	},
	{
		key: '133',
		name: 'Kenya',
		color: '#e6c854',
	},
];

const pixelMmRatio = 0.26458; // TODO always same for all devices?
export const reportPxHeight =
	(styleVariables.reportPageHeightMm - 2 * styleVariables.reportPagePaddingMm) /
	pixelMmRatio;
