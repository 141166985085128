import {
	benchmarkMap1Key,
	benchmarkMap2Key,
	defaultBenchmarkAreaTreeLevelKey,
	defaultExploreAreaTreeLevelKey,
	exploreMap1Key,
	xCubeViewKey,
	auSelectionKey,
	unhabIndicatorLayersRadioGroupKey,
	benchmarkArea1SelectionKey,
	benchmarkArea2SelectionKey,
} from '../../../constants/app';
import styles from '../../../utils/styles';
import layerUtils from '../../../utils/layers';
import limassolAreas from '../../layers/limassolAreas';
export default [
	{
		key: '6f59d02b-7055-4292-ba4a-939d616f9ea9',
		data: {
			nameInternal: 'explore',
			nameDisplay: 'Explore',
			state: {
				components: {
					App: {
						displayScale: true,
						displayTooltipsInMap: true,
					},
				},
				maps: {
					maps: {
						[exploreMap1Key]: {
							key: exploreMap1Key,
							data: {
								metadataModifiers: {
									areaTreeLevelKey: defaultExploreAreaTreeLevelKey,
								},
								backgroundLayer: {
									key: 'cartoLightNoLabels',
									thumbnail: 'cartoDB_VoyagerNoLabels',
									name: 'Base (no labels)',
									type: 'wmts',
									defaultLight: true,
									options: {
										url: 'https://{s}.basemaps.cartocdn.com/rastertiles/light_nolabels/{z}/{x}/{y}.png',
									},
								},
								view: {},
								layers: [
									{
										key: 'GHS_BUILT_LCR',
										filterByActive: {
											application: true,
											areaTreeLevel: true,
										},
										radioGroup: unhabIndicatorLayersRadioGroupKey,
										options: {
											selectable: true,
											selected: {
												[auSelectionKey]: {},
											},
											attributes: {
												relative: 'c09bac02-f276-4ea4-a5f6-e090a8313454',
											},
											style: styles.getByKey('GHS_BUILT_LCR_2020'),
										},
									},
								],
							},
							name: null,
						},
					},
					sets: {
						['mapSet1']: {
							key: 'mapSet1',
							data: {
								view: {
									center: {
										lat: -0.3,
										lon: 37.3,
									},
									boxRange: 1500000,
								},
								viewLimits: {
									boxRange: [5000, 1500000],
								},
							},
							maps: [exploreMap1Key],
							sync: {
								roll: true,
								tilt: true,
								range: true,
								center: true,
								heading: true,
								boxRange: true,
							},
							activeMapKey: exploreMap1Key,
						},
					},
					activeSetKey: 'mapSet1',
				},
				unhab: {
					areaTreeTypes: {activeKey: 'regions'},
					configuration: {
						mapSetTools: {
							mapSet1: {
								open: [
									'zoomControls',
									'backgroundLayersControl',
									'mapAttribution',
									'scale',
									'compareMaps',
									'lockMaps',
									'keepScale',
									'dataSelectionsControl',
								],
								available: ['zoomControls', 'backgroundLayersControl'],
								componentsByKey: {
									zoomControls: {
										icon: 'plus-thick',
										title: 'Zoom controls',
									},
									mapAttribution: {
										icon: 'info',
										title: 'Map Attribution',
									},
									backgroundLayersControl: {
										icon: 'layers',
										title: 'Background layers control',
									},
								},
							},
						},
					},
				},
			},
		},
	},
	{
		key: 'a068c9c2-3dbf-4818-87e6-03418bc467a8',
		data: {
			nameInternal: 'benchmark',
			nameDisplay: 'Benchmark',
			state: {
				components: {
					Maps: {
						mode: 'set',
					},
					App: {
						displayScale: true,
						displayTooltipsInMap: true,
					},
				},
				maps: {
					inUse: {maps: [benchmarkMap1Key, benchmarkMap2Key]},
					maps: {
						[benchmarkMap1Key]: {
							key: benchmarkMap1Key,
							data: {
								metadataModifiers: {
									areaTreeLevelKey: defaultBenchmarkAreaTreeLevelKey,
								},
								view: {},
								layers: [
									layerUtils.getVectorLayerDefinitionWithSelection(
										{
											key: 'GHS_BUILT_LCR',
											filterByActive: {
												application: true,
												areaTreeLevel: true,
											},
											radioGroup: unhabIndicatorLayersRadioGroupKey,
											options: {
												selectable: true,
												selected: {
													[auSelectionKey]: {},
												},
												attributes: {
													relative: 'c09bac02-f276-4ea4-a5f6-e090a8313454',
												},
												style: styles.getByKey('GHS_BUILT_LCR_2020'),
											},
										},
										benchmarkArea1SelectionKey,
									),
								],
								backgroundLayer: {
									key: 'cartoLightNoLabels',
									thumbnail: 'cartoDB_VoyagerNoLabels',
									name: 'Base (no labels)',
									type: 'wmts',
									defaultLight: true,
									options: {
										url: 'https://{s}.basemaps.cartocdn.com/rastertiles/light_nolabels/{z}/{x}/{y}.png',
									},
								},
							},
							name: null,
						},
						[benchmarkMap2Key]: {
							key: benchmarkMap2Key,
							data: {
								metadataModifiers: {
									areaTreeLevelKey: defaultBenchmarkAreaTreeLevelKey,
								},
								view: {},
								layers: [
									layerUtils.getVectorLayerDefinitionWithSelection(
										{
											key: 'GHS_BUILT_LCR',
											filterByActive: {
												application: true,
												areaTreeLevel: true,
											},
											radioGroup: unhabIndicatorLayersRadioGroupKey,
											options: {
												selectable: true,
												selected: {
													[auSelectionKey]: {},
												},
												attributes: {
													relative: 'c09bac02-f276-4ea4-a5f6-e090a8313454',
												},
												style: styles.getByKey('GHS_BUILT_LCR_2020'),
											},
										},
										benchmarkArea2SelectionKey,
									),
								],
								backgroundLayer: {
									key: 'cartoLightNoLabels',
									thumbnail: 'cartoDB_VoyagerNoLabels',
									name: 'Base (no labels)',
									type: 'wmts',
									defaultLight: true,
									options: {
										url: 'https://{s}.basemaps.cartocdn.com/rastertiles/light_nolabels/{z}/{x}/{y}.png',
									},
								},
							},
							name: null,
						},
					},
					sets: {
						['mapSetBenchmark']: {
							key: 'mapSetBenchmark',
							data: {
								view: {
									boxRange: 700000,
									center: {lat: 0.5, lon: 38},
								},
								viewLimits: {
									boxRange: [5000, 1500000],
								},
							},
							maps: [benchmarkMap1Key, benchmarkMap2Key],
							sync: {
								roll: true,
								tilt: true,
								boxRange: false,
								center: false,
								heading: true,
							},
							activeMapKey: benchmarkMap1Key,
						},
					},
					activeSetKey: 'mapSetBenchmark',
				},
				unhab: {
					areaTreeTypes: {activeKey: 'regions'},
					configuration: {
						mapSetTools: {
							mapSetBenchmark: {
								open: [
									'zoomControls',
									'backgroundLayersControl',
									'mapAttribution',
									'scale',
									'compareMaps',
									'keepScale',
								],
								componentsByKey: {
									zoomControls: {
										icon: 'plus-thick',
										title: 'Zoom controls',
									},
									mapAttribution: {
										icon: 'info',
										title: 'Map Attribution',
									},
									backgroundLayersControl: {
										icon: 'layers',
										title: 'Background layers control',
									},
								},
							},
						},
					},
				},
			},
		},
	},
	{
		key: '7c6dbfaf-1ee7-43db-a5db-8eda9d8182c4',
		data: {
			nameInternal: 'report',
			nameDisplay: 'Report',
			state: {
				components: {
					Maps: {
						mode: 'set',
					},
					App: {
						displayScale: true,
						displayTooltipsInMap: true,
					},
				},
				maps: {
					inUse: {maps: [benchmarkMap1Key, benchmarkMap2Key]},
					maps: {
						[benchmarkMap1Key]: {
							key: benchmarkMap1Key,
							data: {
								metadataModifiers: {
									areaTreeLevelKey: defaultBenchmarkAreaTreeLevelKey,
								},
								view: {},
								layers: [
									layerUtils.getVectorLayerDefinitionWithSelection(
										{
											key: 'GHS_BUILT_LCR',
											filterByActive: {
												application: true,
												areaTreeLevel: true,
											},
											radioGroup: unhabIndicatorLayersRadioGroupKey,
											options: {
												selectable: true,
												selected: {
													[auSelectionKey]: {},
												},
												attributes: {
													relative: 'c09bac02-f276-4ea4-a5f6-e090a8313454',
												},
												style: styles.getByKey('GHS_BUILT_LCR_2020'),
											},
										},
										benchmarkArea1SelectionKey,
									),
								],
								backgroundLayer: {
									key: 'cartoLightNoLabels',
									thumbnail: 'cartoDB_VoyagerNoLabels',
									name: 'Base (no labels)',
									type: 'wmts',
									defaultLight: true,
									options: {
										url: 'https://{s}.basemaps.cartocdn.com/rastertiles/light_nolabels/{z}/{x}/{y}.png',
									},
								},
							},
							name: null,
						},
						[benchmarkMap2Key]: {
							key: benchmarkMap2Key,
							data: {
								metadataModifiers: {
									areaTreeLevelKey: defaultBenchmarkAreaTreeLevelKey,
								},
								view: {},
								layers: [
									layerUtils.getVectorLayerDefinitionWithSelection(
										{
											key: 'GHS_BUILT_LCR',
											filterByActive: {
												application: true,
												areaTreeLevel: true,
											},
											radioGroup: unhabIndicatorLayersRadioGroupKey,
											options: {
												selectable: true,
												selected: {
													[auSelectionKey]: {},
												},
												attributes: {
													relative: 'c09bac02-f276-4ea4-a5f6-e090a8313454',
												},
												style: styles.getByKey('GHS_BUILT_LCR_2020'),
											},
										},
										benchmarkArea2SelectionKey,
									),
								],
								backgroundLayer: {
									key: 'cartoLightNoLabels',
									thumbnail: 'cartoDB_VoyagerNoLabels',
									name: 'Base (no labels)',
									type: 'wmts',
									defaultLight: true,
									options: {
										url: 'https://{s}.basemaps.cartocdn.com/rastertiles/light_nolabels/{z}/{x}/{y}.png',
									},
								},
							},
							name: null,
						},
					},
					sets: {
						['mapSetBenchmark']: {
							key: 'mapSetBenchmark',
							data: {
								view: {
									boxRange: 700000,
									center: {lat: 0.5, lon: 38},
								},
								viewLimits: {
									boxRange: [5000, 1500000],
								},
							},
							maps: [benchmarkMap1Key, benchmarkMap2Key],
							sync: {
								roll: true,
								tilt: true,
								boxRange: false,
								center: false,
								heading: true,
							},
							activeMapKey: benchmarkMap1Key,
						},
					},
					activeSetKey: 'mapSetBenchmark',
				},
				unhab: {
					areaTreeTypes: {activeKey: 'regions'},
					configuration: {
						mapSetTools: {
							mapSetBenchmark: {
								open: [
									'zoomControls',
									'backgroundLayersControl',
									'mapAttribution',
									'scale',
								],
								componentsByKey: {
									zoomControls: {
										icon: 'plus-thick',
										title: 'Zoom controls',
									},
									mapAttribution: {
										icon: 'info',
										title: 'Map Attribution',
									},
									backgroundLayersControl: {
										icon: 'layers',
										title: 'Background layers control',
									},
								},
							},
						},
					},
				},
			},
		},
	},
	{
		key: xCubeViewKey,
		data: {
			nameInternal: 'xcube',
			nameDisplay: 'Limassol',
			state: {
				components: {
					Maps: {
						mode: 'set',
					},
					App: {
						displayScale: true,
						displayTooltipsInMap: true,
					},
				},
				maps: {
					inUse: {maps: ['map-1']},
					maps: {
						'map-1': {
							key: 'map-1',
							data: {
								backgroundLayer: {
									key: 'cartoLightNoLabels',
									thumbnail: 'cartoDB_VoyagerNoLabels',
									name: 'Base (no labels)',
									type: 'wmts',
									defaultLight: true,
									options: {
										url: 'https://{s}.basemaps.cartocdn.com/rastertiles/light_nolabels/{z}/{x}/{y}.png',
									},
								},
								view: {},
								layers: [
									{
										key: 'city-cubes~Limassol_S2_NDVI_MCI_2021_2022_1x1024x1024-1.0.0.levels.B02',
										layerKey:
											'city-cubes~Limassol_S2_NDVI_MCI_2021_2022_1x1024x1024-1.0.0.levels.B02',
										legendType: 'xcube',
										type: 'wmts',
										options: {
											url: 'https://xc-limassol-api.brockmann-consult.de/api/datasets/city-cubes~Limassol_S2_NDVI_MCI_2021_2022_1x1024x1024-1.0.0.levels/vars/B02/tiles2/{z}/{y}/{x}?crs=EPSG:3857&cbar=bone&vmin=0&vmax=0.25&time=2022-02-07T08:40:33Z',
										},
										radioGroup: 'xcube',
										metadata: {
											group: 'S2 Limassol with NDVI, MCI ',
											groupKey:
												'city-cubes~Limassol_S2_NDVI_MCI_2021_2022_1x1024x1024-1.0.0.levels',
											variable: 'B02',
											legend: {max: 0.25, min: 0, name: 'bone'},
											layerTemplate: {
												data: {
													nameDisplay: ' B02',
													nameInternal:
														'city-cubes~Limassol_S2_NDVI_MCI_2021_2022_1x1024x1024-1.0.0.levels.B02',
												},
											},
										},
										times: [
											'2021-01-03T08:40:34Z',
											'2021-01-08T08:40:33Z',
											'2021-01-13T08:40:35Z',
											'2021-01-18T08:40:34Z',
											'2021-01-23T08:40:35Z',
											'2021-01-28T08:40:34Z',
											'2021-02-07T08:40:33Z',
											'2021-02-12T08:40:33Z',
											'2021-02-17T08:40:32Z',
											'2021-02-22T08:40:34Z',
											'2021-02-27T08:40:33Z',
											'2021-03-04T08:40:34Z',
											'2021-03-09T08:40:33Z',
											'2021-03-14T08:40:33Z',
											'2021-03-19T08:40:33Z',
											'2021-03-24T08:40:32Z',
											'2021-04-03T08:40:31Z',
											'2021-04-08T08:40:31Z',
											'2021-04-13T08:40:28Z',
											'2021-04-18T08:40:29Z',
											'2021-04-23T08:40:30Z',
											'2021-04-28T08:40:29Z',
											'2021-05-03T08:40:32Z',
											'2021-05-08T08:40:32Z',
											'2021-05-13T08:40:34Z',
											'2021-05-18T08:40:33Z',
											'2021-05-28T08:40:35Z',
											'2021-06-02T08:40:35Z',
											'2021-06-07T08:40:35Z',
											'2021-06-12T08:40:35Z',
											'2021-06-17T08:40:35Z',
											'2021-06-22T08:40:35Z',
											'2021-06-27T08:40:35Z',
											'2021-07-02T08:40:36Z',
											'2021-07-07T08:40:36Z',
											'2021-07-12T08:40:37Z',
											'2021-07-22T08:40:37Z',
											'2021-07-27T08:40:36Z',
											'2021-08-01T08:40:37Z',
											'2021-08-06T08:40:35Z',
											'2021-08-11T08:40:37Z',
											'2021-08-16T08:40:34Z',
											'2021-08-21T08:40:36Z',
											'2021-08-26T08:40:32Z',
											'2021-08-31T08:40:35Z',
											'2021-09-05T08:40:30Z',
											'2021-09-15T08:40:30Z',
											'2021-09-20T08:40:37Z',
											'2021-09-25T08:40:32Z',
											'2021-09-30T08:40:38Z',
											'2021-10-05T08:40:34Z',
											'2021-10-10T08:40:39Z',
											'2021-10-15T08:40:35Z',
											'2021-10-20T08:40:39Z',
											'2021-10-25T08:40:36Z',
											'2021-10-30T08:40:39Z',
											'2021-11-14T08:40:33Z',
											'2021-11-19T08:40:34Z',
											'2021-11-24T08:40:30Z',
											'2021-11-29T08:40:34Z',
											'2021-12-04T08:40:29Z',
											'2021-12-09T08:40:33Z',
											'2021-12-19T08:40:35Z',
											'2021-12-24T08:40:29Z',
											'2022-01-03T08:40:30Z',
											'2022-01-08T08:40:35Z',
											'2022-01-13T08:40:30Z',
											'2022-01-18T08:40:34Z',
											'2022-01-23T08:40:29Z',
											'2022-01-28T08:40:34Z',
											'2022-02-02T08:40:31Z',
											'2022-02-07T08:40:33Z',
										],
									},
									limassolAreas,
								],
							},
							name: null,
						},
					},
					sets: {
						['mapSet1']: {
							key: 'mapSet1',
							data: {
								view: {
									center: {
										lat: 34.68,
										lon: 33.03,
									},
									boxRange: 7000,
								},
								viewLimits: {
									boxRange: [5000, 1500000],
								},
							},
							maps: ['map-1'],
							sync: {
								roll: true,
								tilt: true,
								range: true,
								center: true,
								heading: true,
								boxRange: true,
							},
							activeMapKey: 'map-1',
						},
					},
					activeSetKey: 'mapSet1',
				},
				unhab: {
					configuration: {
						mapSetTools: {
							mapSet1: {
								open: [
									'zoomControls',
									'backgroundLayersControl',
									'mapAttribution',
									'scale',
								],
								componentsByKey: {
									zoomControls: {
										icon: 'plus-thick',
										title: 'Zoom controls',
									},
									mapAttribution: {
										icon: 'info',
										title: 'Map Attribution',
									},
									backgroundLayersControl: {
										icon: 'layers',
										title: 'Background layers control',
									},
								},
							},
						},
					},
				},
			},
		},
	},
];
