const ghsPopulationLayerCommonOptions = {
	unit: 'habitants',
	useChannel: 1,
	useHeatMap: true,
	colorScale: [
		'#e7e1ef',
		'#d4b9da',
		'#c994c7',
		'#df65b0',
		'#e7298a',
		'#ce1256',
		'#91003f',
	],
	colorScaleValueRange: [0, 5, 20, 100, 300, 500, 1000, 5000],
	clipLow: 0,
	clipHigh: 50000,
};

const populationTags = [
	'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
	'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert

	'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
	'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
	'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global

	'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
	'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population

	'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
	'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
];

const ghsBuiltUpLayerCommonOptions = {
	unit: 'm2',
	useChannel: 1,

	useHeatMap: true,
	colorScale: ['#0d0887', '#7e03a8', '#cb4778', '#f89541', '#f0f921'],
	colorScaleValueRange: [1, 2500, 5000, 7500, 10000],
	clipLow: 0,
	clipHigh: 10000,
};

const builtUpTags = [
	'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
	'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert

	'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
	'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
	'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global

	'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
	'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land

	'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
	'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
];

export const ghsBuiltUpLayerKeys = [
	'GHSBuiltUp1975',
	'GHSBuiltUp1980',
	'GHSBuiltUp1985',
	'GHSBuiltUp1990',
	'GHSBuiltUp1995',
	'GHSBuiltUp2000',
	'GHSBuiltUp2005',
	'GHSBuiltUp2010',
	'GHSBuiltUp2015',
	'GHSBuiltUp2020',
	'GHSBuiltUp2025',
	'GHSBuiltUp2030',
];

export const ghsPopulationLayerKeys = [
	'GHSPopulation1975',
	'GHSPopulation1980',
	'GHSPopulation1985',
	'GHSPopulation1990',
	'GHSPopulation1995',
	'GHSPopulation2000',
	'GHSPopulation2005',
	'GHSPopulation2010',
	'GHSPopulation2015',
	'GHSPopulation2020',
	'GHSPopulation2025',
	'GHSPopulation2030',
];

export default {
	GHSBuiltUp1975: {
		key: 'GHSBuiltUp1975',
		layerTemplateKey: 'e951246d-cfbf-406d-8eee-5a3cad0a3cf7',
		tags: [...builtUpTags],
		options: {...ghsBuiltUpLayerCommonOptions},
	},
	GHSBuiltUp1980: {
		key: 'GHSBuiltUp1980',
		layerTemplateKey: '91cf5853-d0cc-4091-aa65-4bf60cfc0112',
		tags: [...builtUpTags],
		options: {...ghsBuiltUpLayerCommonOptions},
	},
	GHSBuiltUp1985: {
		key: 'GHSBuiltUp1985',
		layerTemplateKey: '3a3ca288-cd9b-4179-832c-a48e38ac11df',
		tags: [...builtUpTags],
		options: {...ghsBuiltUpLayerCommonOptions},
	},
	GHSBuiltUp1990: {
		key: 'GHSBuiltUp1990',
		layerTemplateKey: 'cb54bd67-5d65-4459-a8f6-6dab98b6fccb',
		tags: [...builtUpTags],
		options: {...ghsBuiltUpLayerCommonOptions},
	},
	GHSBuiltUp1995: {
		key: 'GHSBuiltUp1995',
		layerTemplateKey: 'd2d4848b-209c-49c1-b6ce-e0608ad614f7',
		tags: [...builtUpTags],
		options: {...ghsBuiltUpLayerCommonOptions},
	},
	GHSBuiltUp2000: {
		key: 'GHSBuiltUp2000',
		layerTemplateKey: 'cfdb9200-b4ad-4450-bf68-ae2039091673',
		tags: [...builtUpTags],
		options: {...ghsBuiltUpLayerCommonOptions},
	},
	GHSBuiltUp2005: {
		key: 'GHSBuiltUp2005',
		layerTemplateKey: '2fc360ea-fc12-4473-be59-beeade707435',
		tags: [...builtUpTags],
		options: {...ghsBuiltUpLayerCommonOptions},
	},
	GHSBuiltUp2010: {
		key: 'GHSBuiltUp2010',
		layerTemplateKey: '758e371a-3d24-4fc6-bc6c-b6a2200629e6',
		tags: [...builtUpTags],
		options: {...ghsBuiltUpLayerCommonOptions},
	},
	GHSBuiltUp2015: {
		key: 'GHSBuiltUp2015',
		layerTemplateKey: '177d5a0e-371f-41c3-b3fd-b7198c3b8953',
		tags: [...builtUpTags],
		options: {...ghsBuiltUpLayerCommonOptions},
	},
	GHSBuiltUp2020: {
		key: 'GHSBuiltUp2020',
		layerTemplateKey: 'acc8b27a-5103-4e9e-b835-c224ce730cd5',
		tags: [...builtUpTags],
		options: {...ghsBuiltUpLayerCommonOptions},
	},
	GHSBuiltUp2025: {
		key: 'GHSBuiltUp2025',
		layerTemplateKey: '5f1782ef-bdbb-416e-888d-5147aa2a5beb',
		tags: [...builtUpTags],
		options: {...ghsBuiltUpLayerCommonOptions},
	},
	GHSBuiltUp2030: {
		key: 'GHSBuiltUp2030',
		layerTemplateKey: '8bbfcc16-5d14-43d7-94e4-c696da9e9a72',
		tags: [...builtUpTags],
		options: {...ghsBuiltUpLayerCommonOptions},
	},
	GHSPopulation1975: {
		key: 'GHSPopulation1975',
		layerTemplateKey: 'ea44a22b-86fb-4088-9161-b1cbc148b52e',
		tags: [...populationTags],
		options: {...ghsPopulationLayerCommonOptions},
	},
	GHSPopulation1980: {
		key: 'GHSPopulation1980',
		layerTemplateKey: 'cc4678e4-2b5c-4e81-b66d-f032fa4bc579',
		tags: [...populationTags],
		options: {...ghsPopulationLayerCommonOptions},
	},
	GHSPopulation1985: {
		key: 'GHSPopulation1985',
		layerTemplateKey: '4cb66ac4-f6f4-4b3a-ac8e-5e0886521a96',
		tags: [...populationTags],
		options: {...ghsPopulationLayerCommonOptions},
	},
	GHSPopulation1990: {
		key: 'GHSPopulation1990',
		layerTemplateKey: '1f35df47-c748-47c1-a1b5-b807202b0519',
		tags: [...populationTags],
		options: {...ghsPopulationLayerCommonOptions},
	},
	GHSPopulation1995: {
		key: 'GHSPopulation1995',
		layerTemplateKey: 'c84ea6ed-562f-40a7-808e-2e3ade45d552',
		tags: [...populationTags],
		options: {...ghsPopulationLayerCommonOptions},
	},
	GHSPopulation2000: {
		key: 'GHSPopulation2000',
		layerTemplateKey: '8237aa4a-7366-4147-934b-c77abb59262c',
		tags: [...populationTags],
		options: {...ghsPopulationLayerCommonOptions},
	},
	GHSPopulation2005: {
		key: 'GHSPopulation2005',
		layerTemplateKey: 'bdb9d964-58e0-4f68-b47b-6fbb9247879b',
		tags: [...populationTags],
		options: {...ghsPopulationLayerCommonOptions},
	},
	GHSPopulation2010: {
		key: 'GHSPopulation2010',
		layerTemplateKey: '334ab74d-2b24-4d9e-b5c0-6b3f88f58e12',
		tags: [...populationTags],
		options: {...ghsPopulationLayerCommonOptions},
	},
	GHSPopulation2015: {
		key: 'GHSPopulation2015',
		layerTemplateKey: '0992d168-1ea0-4a9f-a3bd-906a1fcb8927',
		tags: [...populationTags],
		options: {...ghsPopulationLayerCommonOptions},
	},
	GHSPopulation2020: {
		key: 'GHSPopulation2020',
		layerTemplateKey: '4de7a186-cc7b-4fed-b9fe-f207c9e8e50d',
		tags: [...populationTags],
		options: {...ghsPopulationLayerCommonOptions},
	},
	GHSPopulation2025: {
		key: 'GHSPopulation2025',
		layerTemplateKey: '4b4a909f-95ed-4e32-94ed-779f4595ea95',
		tags: [...populationTags],
		options: {...ghsPopulationLayerCommonOptions},
	},
	GHSPopulation2030: {
		key: 'GHSPopulation2030',
		layerTemplateKey: '62b6ad9d-10f4-44c4-9de6-6f21b6ad904b',
		tags: [...populationTags],
		options: {...ghsPopulationLayerCommonOptions},
	},
};
