const wsfPopulationLayerCommonOptions = {
	unit: 'habitants',
	// useChannel: 1,
	useHeatMap: true,
	colorScale: [
		'#e7e1ef',
		'#d4b9da',
		'#c994c7',
		'#df65b0',
		'#e7298a',
		'#ce1256',
		'#91003f',
	],
	colorScaleValueRange: [0, 5, 20, 100, 300, 500, 1000, 5000],
	clipLow: 0,
	clipHigh: 50000,
};

const wsfBuiltUpLayerCommonOptions = {
	useHeatMap: true,
};

const populationTags = [
	'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
	'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert

	'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
	'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial

	'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
	'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population

	'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
	'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
];

const builtUpTags = [
	'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
	'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert

	'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
	'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial

	'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
	'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land

	'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
	'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
];

export const wsfBuiltUpLayerKeys = [
	'WSFBuiltUp2016',
	'WSFBuiltUp2017',
	'WSFBuiltUp2018',
	'WSFBuiltUp2019',
	'WSFBuiltUp2020',
	'WSFBuiltUp2021',
	'WSFBuiltUp2022',
	'WSFBuiltUp2023',
];
export const wsfPopulationLayerKeys = [
	'WSFPopulation2016',
	'WSFPopulation2017',
	'WSFPopulation2018',
	'WSFPopulation2019',
	'WSFPopulation2020',
	'WSFPopulation2021',
	'WSFPopulation2022',
	'WSFPopulation2023',
];

export default {
	// FIFME - removed on tomas order, is it correct?
	// TODO - remove links from fixtures
	// WSF2019Population_10m: {
	// 	key: 'WSF2019Population_10m',
	// 	layerTemplateKey: '0730ec13-cca0-4d4b-bdc9-1d0af5fcfe53',
	// 	tags: [
	// 		'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert

	// 		'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
	// 		'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global

	// 		'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
	// 		'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population

	// 		'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
	// 		'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
	// 	],
	// 	options: {
	// 		customLegend: {
	// 			attributeValues: {
	// 				'Settlement footprint': {
	// 					color: '#ff0000',
	// 					outlineWidth: 2,
	// 				},
	// 			},
	// 		},
	// 		useChannel: 1,
	// 		blurredTexture: false,
	// 		useColorsBasedOnValues: true,
	// 		colorsBasedOnValues: [[255, '#ff0000']],
	// 	},
	// },
	WSF_Evolution_1985_2023_10m: {
		key: 'WSF_Evolution_1985_2023_10m',
		dependingOnActivePeriod: true,
		blurredTexture: false,
		layerTemplateKey: '9aac5b95-93a3-420f-9efd-e31f031a3995',
		tags: [
			'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert

			'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
			'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial

			'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
			'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land

			'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
		],
		options: {
			useHeatMap: false,
			useChannel: 0,
			blurredTexture: false,

			useColorsBasedOnValues: true,
			colorsBasedOnValues: [
				[1985, '#D50000'],
				[1986, '#DD2200'],
				[1987, '#E54500'],
				[1988, '#EE6700'],
				[1989, '#f68a00'],
				[1990, '#FFAD00'],
				[1991, '#ECBD00'],
				[1992, '#D9CD00'],
				[1993, '#C6DE00'],
				[1994, '#B3EE00'],
				[1995, '#A1FF00'],
				[1996, '#80F200'],
				[1997, '#60E500'],
				[1998, '#40D800'],
				[1999, '#20cb00'],
				[2000, '#00BE00'],
				[2001, '#00B923'],
				[2002, '#00B546'],
				[2003, '#00B069'],
				[2004, '#00AC8C'],
				[2005, '#00A8AF'],
				[2006, '#008AB8'],
				[2007, '#006cc1'],
				[2008, '#004ECA'],
				[2009, '#0030d3'],
				[2010, '#0012DD'],
				[2011, '#190ecd'],
				[2012, '#3232bd'],
				[2013, '#4B07AD'],
				[2014, '#64039d'],
				[2015, '#7E008E'],
				[2016, '#a20096'],
				[2017, '#bf00a6'],
				[2018, '#8d0081'],
				[2019, '#6d0074'],
				[2020, '#600067'],
				[2021, '#4d0062'],
				[2022, '#44006a'],
				[2023, '#2c0062'],
			],
		},
	},
	wsfEvolution1985_2015_30m: {
		key: 'wsfEvolution1985_2015_30m',
		layerTemplateKey: '14541dda-8b6d-4934-b1a7-0d038dfe1321',
		dependingOnActivePeriod: true,
		tags: [
			'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert

			'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
			'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global

			'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
			'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land

			'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
		],
		options: {
			useColorsBasedOnValues: true,
			blurredTexture: false,
			colorsBasedOnValues: [
				[1985, '#D50000'],
				[1986, '#DD2200'],
				[1987, '#E54500'],
				[1988, '#EE6700'],
				[1989, '#f68a00'],
				[1990, '#FFAD00'],
				[1991, '#ECBD00'],
				[1992, '#D9CD00'],
				[1993, '#C6DE00'],
				[1994, '#B3EE00'],
				[1995, '#A1FF00'],
				[1996, '#80F200'],
				[1997, '#60E500'],
				[1998, '#40D800'],
				[1999, '#20cb00'],
				[2000, '#00BE00'],
				[2001, '#00B923'],
				[2002, '#00B546'],
				[2003, '#00B069'],
				[2004, '#00AC8C'],
				[2005, '#00A8AF'],
				[2006, '#008AB8'],
				[2007, '#006cc1'],
				[2008, '#004ECA'],
				[2009, '#0030d3'],
				[2010, '#0012DD'],
				[2011, '#190ecd'],
				[2012, '#3232bd'],
				[2013, '#4B07AD'],
				[2014, '#64039d'],
				[2015, '#7E008E'],
			],
		},
	},
	WSFPopulation2016: {
		key: 'WSFPopulation2016',
		layerTemplateKey: '6035f62e-be47-47bb-adbc-93094db3b33c',
		tags: [...populationTags],
		options: {...wsfPopulationLayerCommonOptions, useChannel: 0},
	},
	WSFPopulation2017: {
		key: 'WSFPopulation2017',
		layerTemplateKey: 'b76c249f-ce49-403b-b6a7-a4dddbaa36e8',
		tags: [...populationTags],
		options: {...wsfPopulationLayerCommonOptions, useChannel: 1},
	},
	WSFPopulation2018: {
		key: 'WSFPopulation2018',
		layerTemplateKey: '3413b5df-524f-44d8-beae-7d2ecb31e1d1',
		tags: [...populationTags],
		options: {...wsfPopulationLayerCommonOptions, useChannel: 2},
	},
	WSFPopulation2019: {
		key: 'WSFPopulation2019',
		layerTemplateKey: 'd8810047-a306-4c05-a21b-a247cdad1693',
		tags: [...populationTags],
		options: {...wsfPopulationLayerCommonOptions, useChannel: 3},
	},
	WSFPopulation2020: {
		key: 'WSFPopulation2020',
		layerTemplateKey: '36c80896-0d4d-4b58-94a8-a7f4af289558',
		tags: [...populationTags],
		options: {...wsfPopulationLayerCommonOptions, useChannel: 4},
	},
	WSFPopulation2021: {
		key: 'WSFPopulation2021',
		layerTemplateKey: '8157bc75-4c53-493c-93fe-e5eeb81c1904',
		tags: [...populationTags],
		options: {...wsfPopulationLayerCommonOptions, useChannel: 5},
	},
	WSFPopulation2022: {
		key: 'WSFPopulation2022',
		layerTemplateKey: '616e1f26-bb2d-459c-b0b0-0158581beac0',
		tags: [...populationTags],
		options: {...wsfPopulationLayerCommonOptions, useChannel: 6},
	},
	WSFPopulation2023: {
		key: 'WSFPopulation2023',
		layerTemplateKey: '54537663-e31d-4bee-9849-36d072b7d5ce',
		tags: [...populationTags],
		options: {...wsfPopulationLayerCommonOptions, useChannel: 7},
	},

	//WSF built-up
	WSFBuiltUp2016: {
		key: 'WSFBuiltUp2016',
		layerTemplateKey: 'f33e045e-2f51-4cde-ac30-a6491ebb99f4',
		tags: [...builtUpTags],
		options: {
			...wsfBuiltUpLayerCommonOptions,
			useChannel: 0,
			colorScale: ['#a20096'],
			customLegend: {
				attributeValues: {
					'Settlement footprint': {
						color: '#a20096',
						outlineWidth: 2,
					},
				},
			},
		},
	},

	WSFBuiltUp2017: {
		key: 'WSFBuiltUp2017',
		layerTemplateKey: 'a58cc540-43f9-47c2-89ee-b293cbda6c5b',
		tags: [...builtUpTags],
		options: {
			...wsfBuiltUpLayerCommonOptions,
			useChannel: 1,
			colorScale: ['#bf00a6'],
			customLegend: {
				attributeValues: {
					'Settlement footprint': {
						color: '#bf00a6',
						outlineWidth: 2,
					},
				},
			},
		},
	},

	WSFBuiltUp2018: {
		key: 'WSFBuiltUp2018',
		layerTemplateKey: 'f5cad024-1ebc-41d3-8c4b-966101be2d06',
		tags: [...builtUpTags],
		options: {
			...wsfBuiltUpLayerCommonOptions,
			useChannel: 2,
			colorScale: ['#8d0081'],
			customLegend: {
				attributeValues: {
					'Settlement footprint': {
						color: '#8d0081',
						outlineWidth: 2,
					},
				},
			},
		},
	},
	WSFBuiltUp2019: {
		key: 'WSFBuiltUp2019',
		layerTemplateKey: '7785741b-440b-410e-9090-f8adc21f4b53',
		tags: [...builtUpTags],
		options: {
			...wsfBuiltUpLayerCommonOptions,
			useChannel: 3,
			colorScale: ['#6d0074'],
			customLegend: {
				attributeValues: {
					'Settlement footprint': {
						color: '#6d0074',
						outlineWidth: 2,
					},
				},
			},
		},
	},
	WSFBuiltUp2020: {
		key: 'WSFBuiltUp2020',
		layerTemplateKey: 'b695796b-3504-4934-aded-85f66631f967',
		tags: [...builtUpTags],
		options: {
			...wsfBuiltUpLayerCommonOptions,
			useChannel: 4,
			colorScale: ['#600067'],
			customLegend: {
				attributeValues: {
					'Settlement footprint': {
						color: '#600067',
						outlineWidth: 2,
					},
				},
			},
		},
	},
	WSFBuiltUp2021: {
		key: 'WSFBuiltUp2021',
		layerTemplateKey: '97f72666-23c0-4663-ae74-fa652c5e16d9',
		tags: [...builtUpTags],
		options: {
			...wsfBuiltUpLayerCommonOptions,
			useChannel: 5,
			colorScale: ['#4d0062'],
			customLegend: {
				attributeValues: {
					'Settlement footprint': {
						color: '#4d0062',
						outlineWidth: 2,
					},
				},
			},
		},
	},
	WSFBuiltUp2022: {
		key: 'WSFBuiltUp2022',
		layerTemplateKey: '3c9809a2-2909-4173-9fae-09808923dc64',
		tags: [...builtUpTags],
		options: {
			...wsfBuiltUpLayerCommonOptions,
			useChannel: 6,
			colorScale: ['#44006a'],
			customLegend: {
				attributeValues: {
					'Settlement footprint': {
						color: '#44006a',
						outlineWidth: 2,
					},
				},
			},
		},
	},
	WSFBuiltUp2023: {
		key: 'WSFBuiltUp2023',
		layerTemplateKey: 'e7d260bf-19b2-4772-8729-28da43e84623',
		tags: [...builtUpTags],
		options: {
			...wsfBuiltUpLayerCommonOptions,
			useChannel: 7,
			colorScale: ['#2c0062'],
			customLegend: {
				attributeValues: {
					'Settlement footprint': {
						color: '#2c0062',
						outlineWidth: 2,
					},
				},
			},
		},
	},

	// todo linkování LT na DS
};
