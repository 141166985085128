import PropTypes from 'prop-types';
import {isArray as _isArray} from 'lodash';
import {useState, cloneElement} from 'react';
import {Icon} from '@gisatcz/ptr-atoms';
import DataLayerControlSettings from '../DataLayerControlSettings';

import LayerSettings from '../LayerSettings';
import LayerOpacityControl from '../LayerSettings/LayerOpacityControl';
import HideLegendControl from '../LayerSettings/HideLegendControl';
import RemoveLayerControl from '../LayerSettings/RemoveLayerControl';
import HideLayerControl from '../LayerSettings/HideLayerControl';
import datasets from '../../../../../../data/datasets';
import Tag from '../../../../Tag';
import {globalTagKey, partialTagKey} from '../../../../../../constants/app';

import './style.scss';

const ItemName = ({children, datasetTags}) => {
	if (_isArray(children)) {
		return (
			<div className="unhab-ItemName">
				<span>{children[0]}</span>
				<span>({children[1]})</span>
			</div>
		);
	} else {
		return (
			<div className="unhab-ItemName">
				{children}
				{datasetTags ? (
					<div className="unhab-ItemName-tags">
						{datasetTags?.map(tag => {
							return <Tag tagKey={tag} key={tag} small />;
						})}
					</div>
				) : null}
			</div>
		);
	}
};

ItemName.propTypes = {
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	datasetTags: PropTypes.array,
};

const SortLayersItem = ({
	name,
	data,
	mapKey,
	isIndicatorLayer,
	SortLayerComponent,
	datasetLayerKey,
}) => {
	const [settingsOpen, setSettingsOpen] = useState(false);

	const dataset = datasets?.find(dataset => {
		return dataset?.key === datasetLayerKey;
	});

	const datasetTags = data?.tags
		? data?.tags?.filter(tag => {
				return tag === globalTagKey || tag === partialTagKey;
			})
		: dataset?.data?.tags?.filter(tag => {
				return tag === globalTagKey || tag === partialTagKey;
			});

	return (
		<li className="unhab-SortLayersItem">
			<div className="unhab-DataLayerControl-header">
				<a>
					<Icon icon="ri-drag-indicator" />
				</a>
				<ItemName datasetTags={datasetTags}>{name}</ItemName>
				<div className="unhab-DataLayerControl-tools">
					{isIndicatorLayer ? (
						<div
							className="unhab-DataLayerControl-infoIcon"
							title="This layer is queryable"
						>
							<Icon icon="ri-comment" />
						</div>
					) : null}
					<HideLayerControl
						isIndicatorLayer={isIndicatorLayer}
						layerKey={data?.key}
						mapKey={mapKey}
						datasetLayerKey={datasetLayerKey}
					/>
					<RemoveLayerControl
						layerKey={data?.key}
						mapKey={mapKey}
						datasetLayerKey={datasetLayerKey}
					/>
					<DataLayerControlSettings
						onClick={setSettingsOpen}
						active={settingsOpen}
					/>
				</div>
			</div>
			{settingsOpen ? (
				<LayerSettings>
					{SortLayerComponent
						? cloneElement(<SortLayerComponent />, {layer: data, mapKey})
						: null}
					<LayerOpacityControl
						data={data}
						mapKey={mapKey}
						datasetLayerKey={datasetLayerKey}
					/>
					<HideLegendControl
						layerKey={data?.key}
						layerTemplateKey={data?.layerTemplateKey}
						mapKey={mapKey}
						datasetLayerKey={datasetLayerKey}
					/>
				</LayerSettings>
			) : null}
		</li>
	);
};

SortLayersItem.propTypes = {
	name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	data: PropTypes.object,
	SortLayerComponent: PropTypes.node,
	mapKey: PropTypes.string,
	isIndicatorLayer: PropTypes.bool,
	datasetLayerKey: PropTypes.string,
};

export default SortLayersItem;
