import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Action from '../../../../../../state/Action';
import Select from '../../../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	const attribute = ownProps.attributeKey
		? Select.attributes.getByKey(state, ownProps.attributeKey)
		: null;
	return {
		attribute,
		layerTemplate:
			Select.layerTemplates.getByKey(state, ownProps.data?.layerTemplateKey) ||
			ownProps.data?.metadata?.layerTemplate,
		// Commented out because of Tomas S. preferences
		// disabled: Select.unhab.isAttributeOutOfCurrentPeriod(
		// 	state,
		// 	ownProps.attributeKey,
		// ),
	};
};

const mapDispatchToPropsFactory = () => {
	return (dispatch, ownProps) => {
		const layerTemplateKey = ownProps.data.layerTemplateKey;
		const componentId = `DataLayerControl_${
			ownProps.data.key || layerTemplateKey
		}`;

		return {
			onMount: () => {
				if (layerTemplateKey) {
					dispatch(
						Action.layerTemplates.useKeys([layerTemplateKey], componentId),
					);
				}
			},
			onAdd: timeout => {
				return dispatch(
					Action.unhab.layers.onLayerChange(
						ownProps.type,
						ownProps.data,
						ownProps.mapKey,
						timeout,
						ownProps.datasetLayerKey,
					),
				);
			},
			onRemove: () => {
				return dispatch(
					Action.unhab.layers.removeLayer(
						ownProps?.data?.layerTemplateKey,
						ownProps.data.key,
						ownProps.mapKey,
					),
				);
			},
		};
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToPropsFactory,
)(Presentation);
