export default {
	1: {
		key: 1,
		data: {
			parent: null,
			name: 'Bolivia',
			extent: [],
		},
		regionsByFeatureKey: {
			'1_1': {
				key: '1_1',
				data: {
					parent: 1,
					name: 'Cochabamba',
					extent: [-66.4668, -17.657, -65.8519, -17.2218],
				},
			},
			'1_2': {
				key: '1_2',
				data: {
					parent: 1,
					name: 'LaPaz',
					extent: [-68.4746, -16.7436, -67.9038, -16.2843],
				},
			},
			'1_3': {
				key: '1_3',
				data: {
					parent: 1,
					name: 'Oruro',
					extent: [-67.2436, -18.1102, -66.9261, -17.8149],
				},
			},
			'1_4': {
				key: '1_4',
				data: {
					parent: 1,
					name: 'Santa Cruz De La Sierra',
					extent: [-63.4574, -18.0525, -62.836, -17.4524],
				},
			},
			'1_5': {
				key: '1_5',
				data: {
					parent: 1,
					name: 'Sucre',
					extent: [-65.4058, -19.1668, -65.133, -18.9125],
				},
			},
			'1_6': {
				key: '1_6',
				data: {
					parent: 1,
					name: 'Tarija',
					extent: [-64.8663, -21.6618, -64.5867, -21.3904],
				},
			},
		},
	},
	2: {
		key: 2,
		data: {
			parent: null,
			name: 'Chile',
			extent: [],
		},
		regionsByFeatureKey: {
			'2_7': {
				key: '2_7',
				data: {
					parent: 1,
					name: 'Concepcion',
					extent: [-73.2739, -36.9896, -72.7747, -36.5458],
				},
			},
			'2_8': {
				key: '2_8',
				data: {
					parent: 1,
					name: 'Iquique',
					extent: [-70.2641, -20.3916, -69.9793, -20.0957],
				},
			},
			'2_9': {
				key: '2_9',
				data: {
					parent: 1,
					name: 'LaSerena',
					extent: [-71.4741, -30.1852, -70.9718, -29.7234],
				},
			},
			'2_10': {
				key: '2_10',
				data: {
					parent: 1,
					name: 'Rancagua',
					extent: [-71.0015, -34.3825, -70.5295, -33.9332],
				},
			},
			'2_11': {
				key: '2_11',
				data: {
					parent: 1,
					name: 'Santiago',
					extent: [-71.134, -33.9741, -70.2523, -33.023],
				},
			},
			'2_12': {
				key: '2_12',
				data: {
					parent: 1,
					name: 'Talca',
					extent: [-71.8474, -35.6646, -71.4231, -35.2574],
				},
			},
			'2_13': {
				key: '2_13',
				data: {
					parent: 1,
					name: 'Temuco',
					extent: [-72.9016, -38.9625, -72.3409, -38.5072],
				},
			},
			'2_14': {
				key: '2_14',
				data: {
					parent: 1,
					name: 'Valparaiso',
					extent: [-71.7701, -33.2461, -71.0616, -32.6686],
				},
			},
		},
	},
	3: {
		key: 3,
		data: {
			parent: null,
			name: 'Kenya',
			extent: [],
		},
		regionsByFeatureKey: {
			'3_15': {
				key: '3_15',
				data: {
					parent: 1,
					name: 'Eldoret',
					extent: [35.1127, 0.363, 35.432, 0.6803],
				},
			},
			'3_16': {
				key: '3_16',
				data: {
					parent: 1,
					name: 'Kisumu',
					extent: [34.6128, -0.2355, 34.9124, 0.0818],
				},
			},
			'3_17': {
				key: '3_17',
				data: {
					parent: 1,
					name: 'Mombasa',
					extent: [39.45, -4.2889, 39.8317, -3.8581],
				},
			},
			'3_18': {
				key: '3_18',
				data: {
					parent: 1,
					name: 'Nairobi',
					extent: [36.5331, -1.473, 37.1232, -1.0343],
				},
			},
			'3_19': {
				key: '3_19',
				data: {
					parent: 1,
					name: 'Nakuru',
					extent: [35.9201, -0.4215, 36.2896, -0.088],
				},
			},
		},
	},
	4: {
		key: 4,
		data: {
			parent: null,
			name: 'Myanmar',
			extent: [],
		},
		regionsByFeatureKey: {
			'4_20': {
				key: '4_20',
				data: {
					parent: 1,
					name: 'Bago',
					extent: [96.3434, 17.1807, 96.6237, 17.484],
				},
			},
			'4_21': {
				key: '4_21',
				data: {
					parent: 1,
					name: 'Hinthada',
					extent: [95.3028, 17.5101, 95.5828, 17.7641],
				},
			},
			'4_22': {
				key: '4_22',
				data: {
					parent: 1,
					name: 'Hpa-An',
					extent: [97.4778, 16.7117, 97.8034, 16.9985],
				},
			},
			'4_23': {
				key: '4_23',
				data: {
					parent: 1,
					name: 'Lashio',
					extent: [97.6116, 22.8147, 97.883, 23.1365],
				},
			},
			'4_24': {
				key: '4_24',
				data: {
					parent: 1,
					name: 'Mandalay',
					extent: [95.7787, 21.6566, 96.293, 22.186],
				},
			},
			'4_25': {
				key: '4_25',
				data: {
					parent: 1,
					name: 'Mawlamyine',
					extent: [97.5146, 16.3007, 97.7816, 16.5956],
				},
			},
			'4_26': {
				key: '4_26',
				data: {
					parent: 1,
					name: 'Mawlamyinegyunn',
					extent: [95.1554, 16.276, 95.3811, 16.4888],
				},
			},
			'4_27': {
				key: '4_27',
				data: {
					parent: 1,
					name: 'Meiktila',
					extent: [95.7226, 20.7509, 96.0515, 21.0386],
				},
			},
			'4_28': {
				key: '4_28',
				data: {
					parent: 1,
					name: 'Monywa',
					extent: [94.9629, 21.9478, 95.3066, 22.2693],
				},
			},
			'4_29': {
				key: '4_29',
				data: {
					parent: 1,
					name: 'Myaungmya',
					extent: [94.7876, 16.4815, 95.0517, 16.7107],
				},
			},
			'4_30': {
				key: '4_30',
				data: {
					parent: 1,
					name: 'Myeik',
					extent: [98.4859, 12.3215, 98.7342, 12.5585],
				},
			},
			'4_31': {
				key: '4_31',
				data: {
					parent: 1,
					name: 'Myitkyina',
					extent: [97.1967, 25.2266, 97.5933, 25.5914],
				},
			},
			'4_32': {
				key: '4_32',
				data: {
					parent: 1,
					name: 'Pathein',
					extent: [94.6176, 16.6541, 94.8921, 16.9409],
				},
			},
			'4_33': {
				key: '4_33',
				data: {
					parent: 1,
					name: 'Taunggyi',
					extent: [96.8275, 20.5932, 97.1733, 20.9639],
				},
			},
			'4_34': {
				key: '4_34',
				data: {
					parent: 1,
					name: 'Yangon',
					extent: [95.8349, 16.5637, 96.4629, 17.1877],
				},
			},
		},
	},
};
