import classnames from 'classnames';
import PropTypes from 'prop-types';
import Checkbox from '../../../../Checkbox';
import Radio from '../../../../Radio';
import {useEffect, useState} from 'react';

import './style.scss';
import Tag from '../../../../Tag';
import {globalTagKey, partialTagKey} from '../../../../../../constants/app';

const DataLayerControl = ({
	active,
	data,
	layerTemplate,
	onAdd,
	onMount,
	onRemove,
	showDescriptionInTitle,
	type = 'checkbox',
	attribute,
	initialized = true,
	removeable = false,
	large,
	interactive,
	disabled,
	tagKeys,
}) => {
	const [loading, setLoading] = useState(false);
	const [initializedLayer, setInitializedLayer] = useState(initialized);
	useEffect(() => {
		if (typeof onMount === 'function') {
			onMount();
		}
	}, []);

	const classes = classnames('unhab-DataLayerControl', {
		'is-active': active,
		'is-large': large,
		'is-disabled': disabled,
		loading: loading,
	});

	const filteredTagKeys = tagKeys?.filter(tag => {
		return tag === globalTagKey || tag === partialTagKey;
	});

	const name = `${
		attribute?.data.nameDisplay ||
		layerTemplate?.data?.nameDisplay ||
		data?.nameDisplay
	}${attribute?.data.mapLayerPeriod ? ' [' + attribute?.data.mapLayerPeriod + ']' : ''}`;
	const description = layerTemplate?.data?.description;

	const addLayer = () => {
		const timeout = initializedLayer ? 0 : 150;
		if (!initializedLayer) {
			setLoading(true);
		}
		onAdd(timeout).then(() => {
			if (!initializedLayer) {
				setLoading(false);
				setInitializedLayer(true);
			}
		});
	};

	return (
		<div className={classes}>
			<div className="unhab-DataLayerControl-header">
				{interactive === false ? (
					<span className="unhab-DataLayerControl-name">{name}</span>
				) : type === 'radio' ? (
					<Radio
						className="unhab-DataLayerControl-name"
						checked={active}
						onChange={active ? onRemove : addLayer}
						onClick={removeable && active ? onRemove : null}
						htmlTitle={name}
					>
						{name}{' '}
						{showDescriptionInTitle ? <span>({description})</span> : null}
					</Radio>
				) : (
					<Checkbox
						className={`unhab-DataLayerControl-name ${
							loading ? ' loading' : ''
						}`}
						checked={loading ? false : active}
						disabled={loading}
						onChange={active ? onRemove : addLayer}
						htmlTitle={name}
					>
						{name}{' '}
						{showDescriptionInTitle ? <span>({description})</span> : null}
					</Checkbox>
				)}
				{filteredTagKeys ? (
					<div className="unhab-DataLayerControl-tags">
						{filteredTagKeys?.map(tag => {
							return <Tag tagKey={tag} key={tag} small />;
						})}
					</div>
				) : null}
			</div>
		</div>
	);
};

DataLayerControl.propTypes = {
	active: PropTypes.bool,
	data: PropTypes.object,
	children: PropTypes.node,
	layerTemplate: PropTypes.object,
	mapKey: PropTypes.string,
	onAdd: PropTypes.func,
	onMount: PropTypes.func,
	onRemove: PropTypes.func,
	showDescriptionInTitle: PropTypes.bool,
	type: PropTypes.string,
	attribute: PropTypes.object,
	initialized: PropTypes.bool,
	removeable: PropTypes.bool, //for radio type, remove layer after click on active
	interactive: PropTypes.bool,
	large: PropTypes.bool,
	disabled: PropTypes.bool,
	datasetKey: PropTypes.string,
	tagKeys: PropTypes.array,
};

export default DataLayerControl;
