import PropTypes from 'prop-types';
import './style.scss';

const LayerLabel = ({activeLayerNames, cityClustersActive, feature, model}) => {
	return (
		<div className="unhab-MapLabels-layers">
			<div className="unhab-MapLabels-layersLabel">
				{cityClustersActive
					? feature?.data?.name + ' '
					: (activeLayerNames?.[0] || 'Layer unset') + ' '}
				<span className="unhab-MapLabels-layersLabelDetail">
					{cityClustersActive
						? model?.data?.nameDisplay + ' '
						: (activeLayerNames?.[1] || '') + ' '}
				</span>
			</div>
		</div>
	);
};

LayerLabel.propTypes = {
	backgroundLayerName: PropTypes.string,
	activeLayerNames: PropTypes.array,
	model: PropTypes.object,
	feature: PropTypes.object,
	cityClustersActive: PropTypes.bool,
};

export default LayerLabel;
