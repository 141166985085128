import {connect} from '@gisatcz/ptr-state';
import Presentation from '../../../../common/SelectedFeatureLabel';
import Action from '../../../../../state/Action';
import Select from '../../../../../state/Select';
import {
	areaNameColumns,
	benchmarkArea1SelectionKey,
	benchmarkArea2SelectionKey,
} from '../../../../../constants/app';
import utils from '../../../../../utils';

const mapStateToProps = (state, ownProps) => {
	let selectionData = Select.selections.getByKey(
		state,
		benchmarkArea1SelectionKey,
	)?.data;
	if (ownProps.index === 1) {
		selectionData = Select.selections.getByKey(
			state,
			benchmarkArea2SelectionKey,
		)?.data;
	}

	const feature = Select.unhab.getFeatureByKeyAndActiveAreaTreeKey(
		state,
		ownProps.featureKey,
	);
	const name =
		feature?.data?.[areaNameColumns[0]] ||
		feature?.data?.[areaNameColumns[1]] ||
		feature?.data?.[areaNameColumns[2]] ||
		ownProps.featureKey;

	const color = utils.getSelectedFeaturePrimaryColor(
		feature?.key,
		selectionData,
	);
	return {
		name,
		color,
		title: 'Zoom to area',
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onClick: () => {
			return dispatch(
				Action.unhab.admStructure.fitActiveMapSetToAdmKey(
					ownProps.featureKey,
					ownProps.index,
				),
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
