import PropTypes from 'prop-types';
import AbsoluteAttribute from '../../../../Indicators/Indicator/AttributeSets/AttributeSet/Attribute/AbsoluteAttribute';
import RelativeAttribute from '../../../../Indicators/Indicator/AttributeSets/AttributeSet/Attribute/RelativeAttribute';
import './style.scss';
import {useEffect} from 'react';

const IndicatorLayerTooltip = ({
	attributeValue,
	activeAreaTreeLevelName,
	ADM0_NAME,
	ADM1_NAME,
	relativeAttribute,
	absoluteValue,
	absoluteAttribute,
	hideTooltip,
	featureKey,
	onFeatureChange,
	valuesForCurrentPeriod,
	currentPeriod,
	dataset,
}) => {
	useEffect(() => {
		onFeatureChange();
	}, [featureKey]);

	return (
		!hideTooltip && (
			<>
				<div className="unhab-MapTooltip">
					<div className="unhab-MapTooltip-title">
						{activeAreaTreeLevelName !== 'Countries' ? ADM1_NAME : ADM0_NAME}
						{activeAreaTreeLevelName !== 'Countries' ? (
							<div className="unhab-MapTooltip-subtitle">({ADM0_NAME})</div>
						) : null}
					</div>
					<div className="unhab-MapTooltip-attributeSet">
						{relativeAttribute?.data?.nameDisplay}
						<span>({dataset?.data?.shortName})</span>
					</div>
					<div className="unhab-MapTooltip-values">
						<div className="unhab-MapTooltip-values-title">
							Full dataset period (in map):
						</div>
						<div className="unhab-MapTooltip-values-content">
							{absoluteValue || attributeValue ? (
								<>
									{absoluteValue ? (
										<AbsoluteAttribute
											value={absoluteValue}
											unit={absoluteAttribute?.data?.unit}
										/>
									) : null}
									{attributeValue ? (
										<RelativeAttribute
											value={attributeValue}
											unit={relativeAttribute?.data?.unit}
										/>
									) : null}
								</>
							) : (
								'-'
							)}
						</div>
					</div>
					{valuesForCurrentPeriod?.length ? (
						<div className="unhab-MapTooltip-values">
							<div className="unhab-MapTooltip-values-title">
								Selected period {`${currentPeriod}`}:
							</div>
							<div className="unhab-MapTooltip-values-content">
								{valuesForCurrentPeriod[0] ? (
									valuesForCurrentPeriod?.length > 1 ? (
										<>
											<AbsoluteAttribute
												value={valuesForCurrentPeriod[0]}
												unit={absoluteAttribute?.data?.unit}
											/>
											<RelativeAttribute
												value={valuesForCurrentPeriod[1]}
												unit={relativeAttribute?.data?.unit}
											/>
										</>
									) : (
										<RelativeAttribute
											value={valuesForCurrentPeriod[0]}
											unit={relativeAttribute?.data?.unit}
										/>
									)
								) : (
									'-'
								)}
							</div>
						</div>
					) : null}
				</div>
			</>
		)
	);
};

IndicatorLayerTooltip.propTypes = {
	attributeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	absoluteValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	attributeKey: PropTypes.string,
	ADM0_NAME: PropTypes.string,
	ADM1_NAME: PropTypes.string,
	activeAreaTreeLevelName: PropTypes.string,
	relativeAttribute: PropTypes.object,
	absoluteAttribute: PropTypes.object,
	hideTooltip: PropTypes.bool,
	featureKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onFeatureChange: PropTypes.func,
	valuesForCurrentPeriod: PropTypes.array,
	currentPeriod: PropTypes.string,
	dataset: PropTypes.object,
};

export default IndicatorLayerTooltip;
