import PropTypes from 'prop-types';
import MapLegendScale from '../components/MapLegendScale';
import MapLegendTitle from '../components/MapLegendTitle';
import MapLayerLegend from '../components/MapLayerLegend';
import AttributeLegend from './AttributeLegend';

const CogBitmapLayer = ({layerDefinition, layerState, expanded}) => {
	const title = layerState?.metadata?.layerTemplate?.data?.nameDisplay;
	const colorScale = layerDefinition?.options?.colorScale;
	const unit = layerDefinition?.options?.unit;
	const colorScaleValueRange = layerDefinition?.options?.colorScaleValueRange;
	const colorsBasedOnValues = layerDefinition?.options?.colorsBasedOnValues;
	const customLegend = layerDefinition?.options?.customLegend;

	let content = null;
	if (customLegend) {
		// FIXME - add common customLegend component
		content = (
			<AttributeLegend
				style={customLegend}
				expanded={expanded}
				unit={unit}
				key={'customLegend'}
			/>
		);
	} else if (colorScale || colorsBasedOnValues) {
		content = (
			<MapLegendScale
				colorsValues={colorsBasedOnValues}
				values={colorScaleValueRange}
				colors={colorScale}
				expanded={expanded}
			/>
		);
	} else {
		// TODO add another types as intervals and values
	}

	return (
		<MapLayerLegend>
			<MapLegendTitle unit={unit}>{title}</MapLegendTitle>
			{content}
		</MapLayerLegend>
	);
};

CogBitmapLayer.propTypes = {
	expanded: PropTypes.bool,
	layerDefinition: PropTypes.object,
	layerState: PropTypes.object,
};

export default CogBitmapLayer;
