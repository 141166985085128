import {
	Select as CommonSelect,
	Action as CommonAction,
} from '@gisatcz/ptr-state';
import Select from '../../Select';
import {
	benchmarkArea1SelectionKey,
	benchmarkArea2SelectionKey,
	benchmarkViewKey,
	benchmarkMap1Key,
	benchmarkMap2Key,
	auSelectionKey,
	reportViewKey,
	datasetsComparisonType,
	areaComparisonType,
	cityClustersAreaTreeKey,
} from '../../../constants/app';
import datasets from '../../../data/datasets';
import {getOriginalLayerKey} from './helpers';

const getCogLayerKey = (prevLayerKey, suffix) => {
	const prevValue = `${prevLayerKey.split(`_suffix`)?.[1]}`;
	const layerKey = `${prevLayerKey.split(`_suffix`)?.[0]}`;
	if (prevValue === suffix) {
		return layerKey;
	} else {
		return `${layerKey}_suffix${suffix}`;
	}
};

const setLayerOpacity = (mapKey, layerKey, layerTemplateKey, opacity) => {
	return (dispatch, getState) => {
		const layers = CommonSelect.maps.getMapLayersStateByMapKey(
			getState(),
			mapKey,
		);

		if (layers) {
			dispatch(CommonAction.maps.removeAllMapLayers(mapKey));
			// TODO temporary solution since COGs do not rerenders on props change and need to change key to correctly rerender
			const updatedLayers = layers?.map(layer => {
				if (
					(layer?.layerTemplateKey &&
						layerTemplateKey &&
						layer?.layerTemplateKey === layerTemplateKey) ||
					(layer?.key && layerKey && layer?.key === layerKey)
				) {
					const newLayerKey = isLayerCog(getState(), layer)
						? getCogLayerKey(layer.key, opacity)
						: layer.key;
					return {
						...layer,
						key: newLayerKey,
						options: {
							...layer.options,
							alpha: opacity,
						},
						opacity: opacity / 100,
					};
				} else {
					return layer;
				}
			});

			dispatch(CommonAction.maps.addMapLayers(mapKey, updatedLayers));
		}
	};
};

const setLayerInteractivity = (mapKey, layerKey, interactive) => {
	return dispatch => {
		dispatch(
			CommonAction.maps.setMapLayerOption(
				mapKey,
				layerKey,
				'selectable',
				interactive,
			),
		);
		dispatch(
			CommonAction.maps.setMapLayerOption(
				mapKey,
				layerKey,
				'hoverable',
				interactive,
			),
		);
	};
};

const updateLayersWithActivePeriod = period => {
	return (dispatch, getState) => {
		const mapSetKey = CommonSelect.maps.getActiveSetKey(getState());
		const maps = CommonSelect.maps.getMapSetMaps(getState(), mapSetKey);
		maps.forEach(map => {
			const mapKey = map.key;
			const layers = map?.data?.layers;
			if (layers?.length) {
				dispatch(CommonAction.maps.removeAllMapLayers(mapKey));
				const updatedLayers = layers?.map(layer => {
					if (layer.dependingOnActivePeriod) {
						const newLayerKey = isLayerCog(getState(), layer)
							? getCogLayerKey(layer.key, period)
							: layer.key;
						return Select.unhab.layers.updateLayerStyleWithActivePeriod(
							{...layer, key: newLayerKey},
							period,
						);
					}
					// Commented out due to Tomas S. preferences
					// else if (layer.options?.attributes) {
					// 	const hideLayer = Select.unhab.isAttributeOutOfCurrentPeriod(
					// 		getState(),
					// 		layer.options?.attributes.relative,
					// 	);
					// 	if (hideLayer) {
					// 		const newStyle = {...layer.options.style};
					// 		newStyle.rules[0].styles[0].fillOpacity = 0;
					// 		return {
					// 			...layer,
					// 			options: {
					// 				...layer.options,
					// 				style: newStyle,
					// 			},
					// 		};
					// 	} else {
					// 		const newStyle = {...layer.options.style};
					// 		newStyle.rules[0].styles[0].fillOpacity = 1;
					// 		return {
					// 			...layer,
					// 			options: {
					// 				...layer.options,
					// 				style: newStyle,
					// 			},
					// 		};
					// 	}
					// }
					else {
						return layer;
					}
				});
				dispatch(CommonAction.maps.addMapLayers(mapKey, updatedLayers));
			}
		});
	};
};

const setLayerLegendHidden = (mapKey, layerKey, layerTemplateKey, hide) => {
	return (dispatch, getState) => {
		// TODO setup this function in ptr-state
		// dispatch(CommonAction.maps.setMapLayerParameter(mapKey, layerKey, layerTemplateKey, 'hideLegend', hide));

		const layers = CommonSelect.maps.getMapLayersStateByMapKey(
			getState(),
			mapKey,
		);

		if (layers) {
			dispatch(CommonAction.maps.removeAllMapLayers(mapKey));
			const updatedLayers = layers?.map(layer => {
				if (
					(layer?.layerTemplateKey &&
						layerTemplateKey &&
						layer?.layerTemplateKey === layerTemplateKey) ||
					(layer?.key && layerKey && layer?.key === layerKey)
				) {
					return {
						...layer,
						hideLegend: hide,
					};
				} else {
					return layer;
				}
			});

			dispatch(CommonAction.maps.addMapLayers(mapKey, updatedLayers));
		}
	};
};

const sortLayers = (mapKey, fromIndex, toIndex) => {
	return (dispatch, getState) => {
		const currentLayers = CommonSelect.maps.getMapLayersStateByMapKey(
			getState(),
			mapKey,
		);

		if (currentLayers?.length) {
			const sortedLayers = [...currentLayers].reverse();
			const item = sortedLayers.splice(fromIndex, 1)[0];
			sortedLayers.splice(toIndex, 0, item);

			dispatch(CommonAction.maps.removeAllMapLayers(mapKey));
			dispatch(CommonAction.maps.addMapLayers(mapKey, sortedLayers.reverse()));
		}
	};
};

const setAllMapsBackgroundLayerByMode = () => {
	return (dispatch, getState) => {
		const mapsInUse = CommonSelect.maps.getAllMapsInUse(getState());
		if (mapsInUse?.length) {
			const layer =
				Select.unhab.layers.getDefaultBackgroundLayerByAppMode(getState());

			if (layer) {
				mapsInUse.forEach(mapKey => {
					dispatch(CommonAction.maps.setMapBackgroundLayer(mapKey, layer));
				});
			}
		}
	};
};

const setLayerSelectedFeatureKeys = (mapKey, layerKey, selectedFeatureKeys) => {
	return (dispatch, getState) => {
		const state = getState();
		const viewKey = Select.views.getActiveKey(state);

		// forbiden interaction when cityClusters are active
		const cityClustersActive =
			Select.unhab.areaTreeTypes.getActiveKey(state) ===
			cityClustersAreaTreeKey;

		// specific selection for benchmark layers
		if (
			(!cityClustersActive && viewKey === benchmarkViewKey) ||
			viewKey === reportViewKey
		) {
			const comparisonType =
				Select.unhab.benchmarkComparisonTypes.getActiveKey(state);
			const featureKey = selectedFeatureKeys?.[0];

			if (comparisonType === datasetsComparisonType) {
				dispatch(
					CommonAction.selections.setFeatureKeysFilterKeys(
						benchmarkArea1SelectionKey,
						[featureKey],
					),
				);
				dispatch(
					CommonAction.selections.setFeatureKeysFilterKeys(
						benchmarkArea2SelectionKey,
						[featureKey],
					),
				);
			} else {
				if (mapKey === benchmarkMap1Key) {
					dispatch(
						CommonAction.selections.setFeatureKeysFilterKeys(
							benchmarkArea1SelectionKey,
							[featureKey],
						),
					);
				} else if (mapKey === benchmarkMap2Key) {
					dispatch(
						CommonAction.selections.setFeatureKeysFilterKeys(
							benchmarkArea2SelectionKey,
							[featureKey],
						),
					);
				}
			}
		}

		// standard selection
		else {
			dispatch(
				CommonAction.maps.setLayerSelectedFeatureKeys(
					mapKey,
					layerKey,
					selectedFeatureKeys,
				),
			);
		}
	};
};

const onLayerChange = (type, data, mapKey, timeout, datasetLayerKey) => {
	return (dispatch, getState) => {
		const state = getState();

		// hack for passing specific selection to general layer definition
		let finalData = data;
		if (data.options.selectable && !data.options.selected) {
			let selected = {[auSelectionKey]: {}};
			const activeViewKey = Select.views.getActiveKey(state);
			if (
				activeViewKey === benchmarkViewKey ||
				activeViewKey === reportViewKey
			) {
				if (mapKey === benchmarkMap1Key) {
					selected = {[benchmarkArea1SelectionKey]: {}};
				} else {
					selected = {[benchmarkArea2SelectionKey]: {}};
				}
			}

			finalData = {
				...data,
				options: {
					...data.options,
					selected,
				},
			};
		}

		if (datasetLayerKey) {
			const datasetLayers = Select.unhab.layers.getLayersByDatasetLayerKey(
				state,
				mapKey,
				datasetLayerKey,
			);

			let opacity = datasetLayers?.[0]?.opacity;
			let alpha = datasetLayers?.[0]?.options?.alpha;
			if (opacity === 0 || opacity > 0) {
				finalData = {
					...finalData,
					opacity: opacity,
					options: {...finalData?.options, alpha: alpha},
				};
			}
		}

		const activeLayers =
			Select.maps.getMapLayersStateByMapKey(state, mapKey) || [];

		return new Promise(resolve => {
			if (type === 'radio') {
				// if radio, then remove layer from same group first
				let layerFromSameGroupIndex =
					activeLayers?.length && activeLayers?.length - 1;
				if (activeLayers?.length) {
					const group = data?.radioGroup;
					if (group) {
						const layerFromSameGroup = activeLayers?.find(
							layer => layer.radioGroup === data?.radioGroup,
						);
						layerFromSameGroupIndex = activeLayers?.findIndex(
							layer => layer.radioGroup === data?.radioGroup,
						);
						if (layerFromSameGroup) {
							dispatch(
								CommonAction.maps.removeMapLayer(
									mapKey,
									layerFromSameGroup.key,
								),
							);
						}
					}
				}
				if (layerFromSameGroupIndex > -1) {
					dispatch(
						CommonAction.maps.addMapLayerToIndex(
							mapKey,
							finalData,
							layerFromSameGroupIndex,
						),
					);
				} else {
					dispatch(CommonAction.maps.addMapLayers(mapKey, [finalData]));
				}
				resolve();
			} else {
				dispatch(CommonAction.maps.addMapLayers(mapKey, [finalData]));
				// TODO
				// Data dont render because renderLayers inside cogLayer is executed twice.
				if (timeout) {
					setTimeout(() => {
						if (data?.layerTemplateKey) {
							dispatch(
								CommonAction.maps.removeMapLayersByLayerTemplateKey(
									mapKey,
									data.layerTemplateKey,
								),
							);
						} else {
							dispatch(CommonAction.maps.removeMapLayer(mapKey, data.key));
						}
						setTimeout(() => {
							dispatch(CommonAction.maps.addMapLayers(mapKey, [finalData]));
							resolve();
						}, timeout);
					}, timeout);
				}
			}
		});
	};
};
const removeLayer = (layerTemplateKey, layerKey, mapKey) => {
	return dispatch => {
		if (layerTemplateKey) {
			dispatch(
				CommonAction.maps.removeMapLayersByLayerTemplateKey(
					mapKey,
					layerTemplateKey,
				),
			);
		} else {
			dispatch(CommonAction.maps.removeMapLayer(mapKey, layerKey));
		}
	};
};

const removeLayersByDatasetLayerKey = (datasetLayerKey, mapKey) => {
	return (dispatch, getState) => {
		if (datasetLayerKey) {
			const state = getState();
			const activeDatasetLayers =
				Select.unhab.layers.getLayersByDatasetLayerKey(
					state,
					mapKey,
					datasetLayerKey,
				);

			activeDatasetLayers.forEach(layer => {
				dispatch(
					CommonAction.maps.removeMapLayersByLayerTemplateKey(
						mapKey,
						layer?.layerTemplateKey,
					),
				);
			});
		}
	};
};

const setLayersOpacityByDatasetLayerKey = (
	mapKey,
	datasetLayerKey,
	opacity,
) => {
	return (dispatch, getState) => {
		const layers = CommonSelect.maps.getMapLayersStateByMapKey(
			getState(),
			mapKey,
		);

		if (layers) {
			dispatch(CommonAction.maps.removeAllMapLayers(mapKey));
			let dataset = datasets?.find(item => {
				return item?.key === datasetLayerKey;
			});
			// TODO temporary solution since COGs do not rerenders on props change and need to change key to correctly rerender
			const updatedLayers = layers?.map(layer => {
				if (
					dataset?.data?.layerKeys?.includes(
						getOriginalLayerKey(layer?.key, layer?.options?.alpha),
					)
				) {
					const newLayerKey = isLayerCog(getState(), layer)
						? getCogLayerKey(layer.key, opacity)
						: layer.key;
					return {
						...layer,
						key: newLayerKey,
						options: {
							...layer.options,
							alpha: opacity,
						},
						opacity: opacity / 100,
					};
				} else {
					return layer;
				}
			});

			dispatch(CommonAction.maps.addMapLayers(mapKey, updatedLayers));
		}
	};
};

const setLayerLegendHiddenByDatasetLayerKey = (
	mapKey,
	datasetLayerKey,
	hide,
) => {
	return (dispatch, getState) => {
		// TODO setup this function in ptr-state
		// dispatch(CommonAction.maps.setMapLayerParameter(mapKey, layerKey, layerTemplateKey, 'hideLegend', hide));

		const layers = CommonSelect.maps.getMapLayersStateByMapKey(
			getState(),
			mapKey,
		);

		let dataset = datasets?.find(item => {
			return item?.key === datasetLayerKey;
		});

		if (layers && datasetLayerKey) {
			dispatch(CommonAction.maps.removeAllMapLayers(mapKey));
			const updatedLayers = layers?.map(layer => {
				if (
					dataset?.data?.layerKeys?.includes(
						getOriginalLayerKey(layer?.key, layer?.options?.alpha),
					)
					// (layer?.layerTemplateKey &&
					// 	layerTemplateKey &&
					// 	layer?.layerTemplateKey === layerTemplateKey) ||
					// (layer?.key && layerKey && layer?.key === layerKey)
				) {
					return {
						...layer,
						hideLegend: hide,
					};
				} else {
					return layer;
				}
			});

			dispatch(CommonAction.maps.addMapLayers(mapKey, updatedLayers));
		}
	};
};

const handleLayersOnDatasetChange = () => {
	return (dispatch, getState) => {
		const state = getState();
		const viewKey = Select.views.getActiveKey(state);

		// handle just for benchmark or report
		if (viewKey === benchmarkViewKey || viewKey === reportViewKey) {
			const mapSetKey = Select.maps.getActiveSetKey(state);
			const maps = Select.maps.getMapSetMaps(state, mapSetKey);
			const datasets = Select.unhab.datasets.getActiveModels(state);
			const comparisonType =
				Select.unhab.benchmarkComparisonTypes.getActiveKey(state);
			const datasetsDefaultLayerKeys = datasets?.map(
				dataset => dataset.data.defaultIndicatorLayerKey,
			);

			maps.forEach((map, index) => {
				map.data.layers.forEach(layer => {
					const isIndicatorLayer = layer?.options?.attributes?.relative;
					if (isIndicatorLayer) {
						if (comparisonType === areaComparisonType) {
							if (layer.key === datasetsDefaultLayerKeys[0]) {
								// layer already in map, do nothing
							} else {
								dispatch(CommonAction.maps.removeMapLayer(map.key, layer.key));
								const newLayer =
									Select.unhab.layers.getIndicatorLayerByLayerKey(
										datasetsDefaultLayerKeys[0],
										map.key,
									);
								dispatch(
									CommonAction.maps.addMapLayerToIndex(map.key, newLayer),
								);
							}
						} else {
							if (layer.key === datasetsDefaultLayerKeys[index]) {
								// layer already in map, do nothing
							} else {
								dispatch(CommonAction.maps.removeMapLayer(map.key, layer.key));
								const newLayer =
									Select.unhab.layers.getIndicatorLayerByLayerKey(
										datasetsDefaultLayerKeys[index],
										map.key,
									);
								dispatch(
									CommonAction.maps.addMapLayerToIndex(map.key, newLayer),
								);
							}
						}
					}
				});
			});
		}
	};
};

// Helpers ---------------------------------------------------
// TODO temporary helpers, remove after fix in ptr-maps/ptr-state
function isLayerCog(state, layerState) {
	let isCog = false;
	const dataSources = CommonSelect.data.spatialDataSources.getIndexed(state, {
		layerTemplateKey: layerState?.layerTemplateKey,
	});
	if (dataSources) {
		isCog = dataSources.some(obj => obj.data?.type === 'cogBitmap');
	}

	return isCog;
}

export default {
	onLayerChange,
	removeLayer,
	setLayerOpacity,
	setLayerInteractivity,
	setLayerLegendHidden,
	setLayerSelectedFeatureKeys,
	setAllMapsBackgroundLayerByMode,
	sortLayers,
	removeLayersByDatasetLayerKey,
	setLayersOpacityByDatasetLayerKey,
	setLayerLegendHiddenByDatasetLayerKey,
	updateLayersWithActivePeriod,
	handleLayersOnDatasetChange,
};
