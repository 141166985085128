// import PropTypes from 'prop-types';
import './style.scss';
import BenchmarkSelectedFeatureLabel from '../../../Benchmark/BenchmarkSidePanel/BenchmarkSelectedFeatureControl/BenchmarkSelectedFeatureLabel';
import BenchmarkSelectedFeatureControl from '../../../Benchmark/BenchmarkSidePanel/BenchmarkSelectedFeatureControl';
import BenchmarkSelectedDatasetControl from '../../../Benchmark/BenchmarkSidePanel/BenchmarkSelectedDatasetControl';
import {defaultPeriod} from '../../../../../constants/app';
import PeriodSelector from '../../../../common/PeriodSelector';

const ReportSelections = () => {
	return (
		<div className="unhab-ReportSelections">
			<div className="unhab-ReportSelections-row">
				<div className="unhab-ReportSelections-name">Areas:</div>
				<div className="unhab-ReportSelections-item">
					<BenchmarkSelectedFeatureControl
						FeatureLabel={BenchmarkSelectedFeatureLabel}
						simpleList
					/>
				</div>
			</div>
			<div className="unhab-ReportSelections-row">
				<div className="unhab-ReportSelections-name">Datasets:</div>
				<div className="unhab-ReportSelections-item">
					<BenchmarkSelectedDatasetControl simpleList />
				</div>
			</div>
			<div className="unhab-ReportSelections-row">
				<div className="unhab-ReportSelections-name">Period:</div>
				<div className="unhab-ReportSelections-item">
					<PeriodSelector defaultPeriod={defaultPeriod} simpleView />
				</div>
			</div>
		</div>
	);
};

ReportSelections.propTypes = {};

export default ReportSelections;
