import {Select as CommonSelect} from '@gisatcz/ptr-state';
import {createSelector} from 'reselect';
import configuration from './configuration/selectors';
import cityClusterFeatures from './cityClusterFeatures/selectors';
import cityClusterMethods from './cityClusterMethods/selectors';
import cityClusterMethodParameters from './cityClusterMethodParameters/selectors';
import datasets from './datasets/selectors';
import datasetSets from './datasetSets/selectors';
import providers from './providers/selectors';
import layers from './layers/selectors';
import features from './features/selectors';
import featuresForScatterPlot from './featuresForScatterPlot/selectors';
import admStructure from './admStructure/selectors';
import udtStructure from './udtStructure/selectors';
import parameterValues from './parameterValues/selectors';
import areaTreeTypes from './areaTreeTypes/selectors';
import benchmarkComparisonTypes from './benchmarkComparisonTypes/selectors';
import benchmark from './benchmark/selectors';
import indicatorsForList from './indicatorsForList/selectors';
import charts from './charts/selectors';
import indicators from './indicators/selectors';
import tags from './tags/selectors';
import moment from 'moment/moment';
import {cloneDeep as _cloneDeep} from 'lodash';
import {
	auSelectionKey,
	exploreMap1Key,
	exploreMap2Key,
	benchmarkMap1Key,
	benchmarkMap2Key,
	benchmarkArea1SelectionKey,
	benchmarkArea2SelectionKey,
	cityClustersAreaTreeKey,
	xCubeViewKey,
	defaultPeriod,
} from '../../constants/app';
import datasetsSelectors from './datasets/selectors';

const getSubstate = state => state.unhab;

const getPeriodExtent = createSelector(
	[datasetsSelectors.getActiveModels],
	activeDatasets => {
		if (activeDatasets) {
			let start = Infinity;
			let end = -Infinity;
			activeDatasets.forEach(dataset => {
				const period = dataset.data.period;
				if (period) {
					const [startPeriod, endPeriod] = period.split('/');
					start = Math.min(start, Number(startPeriod));
					end = Math.max(end, Number(endPeriod));
				}
			});
			return `${start}/${end}`;
		} else {
			return defaultPeriod;
		}
	},
);

const getMapLevelByMapKey = createSelector(
	[
		CommonSelect.maps.getMapByKey,
		CommonSelect.areas.areaTreeLevels.getAllAsObject,
	],
	(map, levels) => {
		const activeMapLevelKey = map?.data?.metadataModifiers?.areaTreeLevelKey;
		return levels?.[activeMapLevelKey];
	},
);

const getCurrentDateRange = createSelector(
	[CommonSelect.periods.getActive],
	period => {
		if (period?.data?.period) {
			const [start, end] = period.data.period.split('/');
			const startString = moment(start).format('YYYY-MM-DDTHH:mm:ssZ');
			const endString = moment(end).format('YYYY-MM-DDTHH:mm:ssZ');
			return [startString, endString];
		} else {
			return null;
		}
	},
);

const hasMapSetSyncedLocation = createSelector(
	[CommonSelect.maps.getMapSetByKey],
	mapSet => {
		if (mapSet) {
			return mapSet.sync.center && mapSet.sync.boxRange;
		} else {
			return false;
		}
	},
);

/**
 * Return feature if activeAreaTreeKey is region or return udt structure item if activeAreaTreeKey is city clusters
 * @param state {Object}
 * @param key {string} feature key
 */
const getFeatureByKeyAndActiveAreaTreeKey = createSelector(
	[features.getByKey, udtStructure.getByKey, areaTreeTypes.getActiveKey],
	(feature, udtStructureFeature, activeAreaTreeKey) => {
		if (activeAreaTreeKey === cityClustersAreaTreeKey) {
			return udtStructureFeature;
		} else {
			return feature;
		}
	},
);

const hasMapSetSyncedJustScale = createSelector(
	[CommonSelect.maps.getMapSetByKey],
	mapSet => {
		if (mapSet) {
			return mapSet.sync.boxRange && !mapSet.sync.center;
		} else {
			return false;
		}
	},
);

const getMapsLayers = state => {
	const mapSetKey = CommonSelect.maps.getActiveSetKey(state);
	const mapsKeys = CommonSelect.maps.getMapSetMapKeys(state, mapSetKey);
	return mapsKeys.map(mapKey => {
		return {
			key: mapKey,
			layers: CommonSelect.maps.getMapLayersStateByMapKey(state, mapKey),
		};
	});
};

/**
 *
 * @param {*} state
 * @param {*} forceRemoveIndicatorsLayers if true, then dataset.data.attributes will be empty
 * @returns
 */
const getIndicatorLayersByProvidersFilteredByActiveTags = (
	state,
	forceRemoveIndicatorsLayers = false,
) => {
	const indicatorAttributesByProvidersFilteredByActiveTags = _cloneDeep(
		layers.getIndicatorLayersByProvidersFilteredByActiveTags(state, true),
	);

	indicatorAttributesByProvidersFilteredByActiveTags.forEach(group => {
		group.data.datasets.forEach(dataset => {
			dataset.data.attributes = [
				...(forceRemoveIndicatorsLayers ? [] : dataset.data.attributes),
			].map(attributeKey =>
				CommonSelect.attributes.getByKey(state, attributeKey),
			);
		});
	});
	return indicatorAttributesByProvidersFilteredByActiveTags;
};

const getDatasetLayersFilteredByActiveTags = state => {
	const wsfLayers = layers.getWsfDatasetLayers(state, true) || [];
	const ghsLayers = layers.getGhsDatasetLayers(state, true) || [];

	return [..._cloneDeep(wsfLayers), ..._cloneDeep(ghsLayers)];
};

/**
 * Check if layer is included in layer tree by layer key in map layer definition or by style attribute key.
 * Checking by style is fallback for SDG attributes which are combined
 * @param {Array} tree
 * @param {Object} mapLayer
 * @returns boolean
 */
const layerIncludedInLayerTree = (tree, mapLayer) => {
	const layerKey = mapLayer.key;
	const sdgAttribute =
		mapLayer?.options?.style?.rules?.[0]?.styles?.[1]?.attributeKey;

	return tree.some(group =>
		group.data.datasets.some(dataset =>
			dataset.data.attributes.some(
				attribute =>
					attribute.data.configuration.columnName === layerKey ||
					attribute.data.configuration.columnName === sdgAttribute,
			),
		),
	);
};

const getMapLayersToRemove = (state, forceRemoveIndicatorsLayers = false) => {
	const mapsLayers = getMapsLayers(state) || [];
	const indicatorLayersByProvidersFilteredByActiveTags =
		getIndicatorLayersByProvidersFilteredByActiveTags(
			state,
			forceRemoveIndicatorsLayers,
		);
	const datasetLayersFilteredByActiveTags =
		getDatasetLayersFilteredByActiveTags(state);

	mapsLayers.forEach(map => {
		map.layers = map.layers?.filter(layer => {
			const layerIncludedInLayerTreeByLayerKey =
				layer?.options?.attributes?.relative &&
				layerIncludedInLayerTree(
					indicatorLayersByProvidersFilteredByActiveTags,
					layer,
				);
			const datasetLayersFiltered = datasetLayersFilteredByActiveTags.some(
				datasetLayer => layer.key === datasetLayer.key,
			);
			return !(layerIncludedInLayerTreeByLayerKey || datasetLayersFiltered);
		});
	});
	return mapsLayers;
};

const isAttributeOutOfCurrentPeriod = createSelector(
	[datasetsSelectors.getByAttributeKey, CommonSelect.periods.getActive],
	(dataset, period) => {
		if (dataset && period) {
			const [datasetStart, datasetEnd] = dataset.data.period.split('/');
			const [start, end] = period.data.period.split('/');
			return (
				Number(start) > Number(datasetStart) || Number(end) < Number(datasetEnd)
			);
		} else {
			return false;
		}
	},
);

const getSelectionKeyByMapKey = mapKey => {
	switch (mapKey) {
		case exploreMap1Key:
		case exploreMap2Key:
			return auSelectionKey;
		case benchmarkMap1Key:
			return benchmarkArea1SelectionKey;
		case benchmarkMap2Key:
			return benchmarkArea2SelectionKey;
	}
};

const getViews = createSelector([CommonSelect.views.getAll], views => {
	if (views) {
		return views.filter(view => view.key !== xCubeViewKey);
	} else {
		return null;
	}
});

export default {
	getSubstate,
	getViews,
	getMapLevelByMapKey,
	getCurrentDateRange,
	hasMapSetSyncedLocation,
	hasMapSetSyncedJustScale,
	getMapLayersToRemove,
	getIndicatorLayersByProvidersFilteredByActiveTags,
	getSelectionKeyByMapKey,
	getFeatureByKeyAndActiveAreaTreeKey,
	getPeriodExtent,
	isAttributeOutOfCurrentPeriod,

	configuration,
	cityClusterFeatures,
	cityClusterMethods,
	cityClusterMethodParameters,
	datasets,
	datasetSets,
	indicatorsForList,
	providers,
	layers,
	features,
	featuresForScatterPlot,
	charts,
	indicators,
	tags,
	admStructure,
	areaTreeTypes,
	benchmarkComparisonTypes,
	benchmark,
	udtStructure,
	parameterValues,
};
