import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Action from '../../../../../../state/Action';
import Select from '../../../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	return {
		activeLayers: Select.maps.getMapLayersStateByMapKey(state, ownProps.mapKey),
	};
};

const mapDispatchToPropsFactory = () => {
	return (dispatch, ownProps) => {
		return {
			onAdd: () => {
				return dispatch(
					Action.unhab.layers.onLayerChange(
						'checkbox',
						ownProps.layer,
						ownProps.mapKey,
						0,
						ownProps.datasetKey,
					),
				);
			},
			onRemove: () => {
				return dispatch(
					Action.unhab.layers.removeLayersByDatasetLayerKey(
						ownProps.datasetKey,
						ownProps.mapKey,
					),
				);
			},
		};
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToPropsFactory,
)(Presentation);
